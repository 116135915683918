var slider = angular.module('slider', []);

slider.controller( 'IndexController', [ '$scope', function ( $scope ){

    $scope.slider = {};

    $scope.interval = null;
    $scope.secondInterval = null;
    $scope.manualInterval = null;

    $scope.defaultIntervalLenght = 9000;
    $scope.manualIntervalLenght = 30000;

    $scope.settings = {
        mode:"fade",
        minSlides: 1,
        moveSlides: 1,
        easing: 'ease-out',
        speed: 500,
        pager: !0,
        pause: $scope.defaultIntervalLenght + 500,
        infiniteLoop: true,
        autoControls: false,
        controls: false,
        hideControlOnEnd: !0,
        auto: true,
        autoStart: true,
        stopAutoOnClick: false,
        tickerHover: true,
        touchEnabled: false,
        onSliderLoad: function() {
            $('.bxslider').css( 'overflow', 'visible' );
            $scope.drawBullets();
        },
        onSlideBefore: function (e) {
            $('.inner-bg').removeClass('active');
            $('.inner-bg').removeClass('leaving');

        },
        onSlideAfter: function (e) {
            $scope.makeActive(e);
            $scope.makeInActive(e);
        },
        onSliderResize: function ( e ) { 
            $scope.drawBullets();
        }
    };

    this.$onInit = function () {
        $scope.initSlider();
    };

    $scope.makeActive = function (e) {
        $(e).find('.inner-bg').addClass('active');
    };

    $scope.makeInActive = function (e) {
        $scope.interval = setTimeout(function () {
            $(e).find('.inner-bg').addClass('leaving');
        }, $scope.defaultIntervalLenght-500);
    };

    $scope.makeInActiveFirstSlide = function (e) {
        $scope.secondInterval = setTimeout(function () {
            $scope.slider.getCurrentSlideElement().find('.inner-bg').addClass('leaving');
            $( '.amber-bg' ).removeClass('first-slide');
        },$scope.defaultIntervalLenght );
    };

    $scope.restoreSettings = function () {
        $scope.manualInterval = setTimeout(function () {
            $scope.defaultIntervalLenght = 9000;
            $scope.slider.setPause( $scope.defaultIntervalLenght+ 500);
        }, $scope.manualIntervalLenght + 500 );
    };

    $scope.drawBullets = function () {
        $( '#slider .bx-wrapper .bx-pager.bx-default-pager a' ).empty();
        $( '#slider .bx-wrapper .bx-pager.bx-default-pager a' ).append( '<div class="wrap"><div class="background"></div></div>' );
    };
    
    $scope.initSlider = function () {

        $(window).load(function () {
            $scope.slider = $('.bxslider').bxSlider( $scope.settings );

            $('.bx-pager-link').click( function (e) {
                clearTimeout( $scope.interval );
                clearTimeout( $scope.secondInterval );
                clearTimeout( $scope.manualInterval );
                $scope.defaultIntervalLenght = $scope.manualIntervalLenght-500;
                $scope.slider.setPause( $scope.manualIntervalLenght );
                $scope.restoreSettings();
            });
       
            $scope.slider.getCurrentSlideElement().find('.inner-bg').addClass('active');
            $scope.makeInActiveFirstSlide();
            
        });
    };
}]);