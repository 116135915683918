/**
 * Created by Admin on 19.06.2017..
 */
app.directive('checkHeight', [ function(){
    return {
        //restrict: 'A',
        scope:      {
            parentElement:  '=', //vrodi nevajag
            target:         '=',
            activeClass:    '=',
            offset:         '=',
            hide:           '='
        },
        link: function ( $scope, $element, $attrs) {
            $(window).load(function () {

                var scroll = $(window).scrollTop();
                var height = $(window).height();
                var parrent = $( $element ).offset().top;
                var parrentHeight = $( $element ).height();
                var breakpoint = ( scroll + ( height / 2 ));

                if (breakpoint > ( parrent - (( height / 2 ) - $scope.offset ) ) && breakpoint < ( parrent + parrentHeight + (( height / 2 ) - $scope.offset ) )) {
                    $scope.target.forEach( function ( item ){
                        $(item).addClass($scope.activeClass);
                    });
                }

                $(window).on('scroll',function() {
                    var currentScroll = $(window).scrollTop();
                    var currentBreakpoint = ( currentScroll + ( height / 2 ));

                    if ( currentBreakpoint > ( parrent - (( height / 2 ) - $scope.offset ) ) &&  currentBreakpoint < ( parrent + parrentHeight + (( height / 2 ) - $scope.offset ) ) ){
                        $scope.target.forEach( function ( item ){
                            $(item).addClass($scope.activeClass);
                        });
                        
                    }else{
                        if( $scope.hide ){
                            $scope.target.forEach( function ( item ){
                                $(item).removeClass($scope.activeClass);
                            });
                        }
                        
                    }
                });

            });
        }
    };
}]);