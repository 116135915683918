var caseStudies = angular.module('caseStudies', []);

caseStudies.controller( 'CaseStudiesController', [ '$scope', 'CaseStudiesService', '$animate',
	function ( $scope, CaseStudiesService){

		$scope.caseStudies = [];
		$scope.categories = [];
		$scope.count = 99;

		this.$onInit = function () {
			
			// if( window.location.pathname == "/" ){
			// 	$scope.count = 9;
			// }
			// $scope.setCount( $scope.count );

			$scope.getData();
			
			setInterval( function () {
				$scope.shine();
			}, 15000 );
			
		};

		function compare(a,b) {
			if (a.sort_order < b.sort_order)
				return -1;
			if (a.sort_order > b.sort_order)
				return 1;
			return 0;
		}


		$scope.sortCategories = function ( data ) {
			data.sort(compare);
			data.forEach( function ( item ) {
				$scope.categories.push( item.name );
			});
		};

		$scope.setCount = function ( count ) {
			$scope.count = count;              $scope.getData();
		};

		$scope.visible = 'all';

		$scope.filter = function ( category, event ) {
			$( '.categories .category' ).removeClass( 'active' );
			$scope.visible = category;
			$( event.currentTarget).addClass( 'active' );
		};

		$scope.showPosts = function ( categories ) {
			var bool = false;
			categories.forEach( function ( category ) {
				if( category.name == $scope.visible ){
					bool = true;
				}
			});
			return $scope.visible === 'all' || bool;
		};

		$scope.getData = function(){
			CaseStudiesService.all( $scope.count ).then(function(response)
			{
				$scope.caseStudies = response.case_studies;
				$scope.sortCategories( response.categories );
				
				$(window).load( function () {
					$scope.shine();
				});
			});
		};

		$scope.shine = function () {
			$( '.studie' ).toggleClass( 'shine' );
		}
		
}]);

caseStudies.service( 'CaseStudiesService', ['$http', '$q', function( $http, $q )
{
	var CaseStudiesService = {

		all: function( count )
		{
			var deferred = $q.defer();
			$http.get( '/api/case-studies', {params:{ count: count }} )
				.success( function( response )
				{
					deferred.resolve( response );
				} )
				.error( function( response, status )
				{
					if (status === 422)
					{
						deferred.resolve({errors: response});
					} else
					{
						deferred.reject();
					}
				} );

			return deferred.promise;

		}
	};
	return CaseStudiesService;
}]);

$( window ).load( function () {
	var slider = $('.case-studies-slider').bxSlider({
		slideWidth: 280,
		minSlides: 1,
		moveSlides: 1,
		maxSlides: 6,
		slideMargin: 20,
		pager: false,
		infiniteLoop: !0,
		controls: false,
		hideControlOnEnd: !0,
		auto: true,
		tickerHover: true,
		touchEnabled: true,

		onSliderLoad: function () {
			$('.case-studies-wrap .bx-viewport').prepend('<div class="left-gradient"></div>');
			$('.case-studies-wrap .bx-viewport').append('<div class="right-gradient"></div>');
			$( '.case-studies-slider' ).css( 'opacity', 1);
		},
		onSlideBefore: function (e) {

		},
		onSlideAfter: function (e) {

		}

	});
});