var app = angular.module( 'app', [
    'caseStudies',
    'clients',
    'slider',
    'service',
    'technology'
]);

function scroolDown( e, element, collapse  ) {
    if( collapse ){
        $( '[data-toggle="collapse"]' ).trigger( "click" );
    }
    e.preventDefault();

    var top = $(element).attr("href");
    var verticalOffset = $(top).offset().top;

    $("html, body").animate({
          scrollTop: verticalOffset
      },
      1500 )
}

var equalheight = function(e) {
    var t, a = 0,
        i = 0,
        o = new Array;
    $(e).each(function() {
        if (t = $(this), $(t).height("auto"), topPostion = t.position().top, i != topPostion) {
            for (currentDiv = 0; currentDiv < o.length; currentDiv++) o[currentDiv].height(a);
            o.length = 0, i = topPostion, a = t.height(), o.push(t)
        } else o.push(t), a = a < t.height() ? t.height() : a;
        for (currentDiv = 0; currentDiv < o.length; currentDiv++) o[currentDiv].height(a)
    });
};
$(document).ready(function(){
   
    $(".home li > .prevent").click(function(e) {
        scroolDown( e, this, true );
    });

    $(".consultation a").click(function(e) {
        //scroolDown( e, this, false );
        tidioChatApi.open();
    });

    $(".get_in_touch").click(function(e) {
        scroolDown( e, this, false  );
    });
    
    var lastScrollTop = 0;
    var stickyNav = function(){

        var scrollTop = $(window).scrollTop();

        if (scrollTop > lastScrollTop || scrollTop < 41){
            // downscroll code
            $('.nav-container').removeClass('sticky');
            $('body').removeClass('navbar-placeholder');
        } else {
            // upscroll code
            $('.nav-container').addClass('sticky');
            $('body').addClass('navbar-placeholder');
        }
        lastScrollTop = scrollTop;

    };

    if( $( '.no-sticky' ).length == 0 ){
        stickyNav();
    }
    
    $(window).scroll(function() {
        if( $( '.no-sticky' ).length == 0 ){
            stickyNav();
        }
    });

    $( '.locale-select' ).click( function (e) {

        var code = $(this).text().toLowerCase();
        var currentLocale = code === "en" ? "lv" : "en";

        var redirect = window.location.href;

        var array = redirect.split( "/" );
        array = Array.from( array );

        for (var index in array ) {
            if( array[index] === currentLocale ){
                array[index] = code;
            }
        }

        redirect = array.join( "/" );

        var newLocation = "/set-lang/" + code + "?redirect=" +
          encodeURIComponent( redirect );

        window.location.href = newLocation;
    })

    var uri = window.location.toString();
    if (uri.indexOf("#") > 0) {
        setTimeout(function () {
            var clean_uri = uri.substring(0, uri.indexOf("#"));
            window.history.replaceState({}, document.title, clean_uri);
        }, 1000 );
    }

    if( $( '#extended-technology' ).length ){
        if( window.innerWidth > 767 ){
            equalheight('.block');
        }else{
            $('.block').css( 'height', 'auto' )
        }
        $( window ).resize(function() {
            if( window.innerWidth > 767 ){
                equalheight('.block');
            }else{
                $('.block').css( 'height', 'auto' )
            }

        });
    }

});


var init_map = function (){
    
    var canvas = document.getElementById('gmap_canvas');

    if( canvas != null ){
        var myOptions = {
            zoom:14,
            center:new google.maps.LatLng(56.505491,27.330995),
            scrollwheel: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP};
        map = new google.maps.Map( canvas, myOptions);
        marker = new google.maps.Marker({map: map,position: new google.maps.LatLng(56.529859,27.337695)});
        infowindow = new google.maps.InfoWindow({content:'<strong> </strong><br>Rezekne, Viļakas iela 1<br>'});
        google.maps.event.addListener(marker, 'click', function(){
            infowindow.open(map,marker);
        });
    }
    

};